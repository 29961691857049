import React, { useEffect, useState, useContext } from "react"
import { motion } from 'framer-motion';

import './CSS/contactCSS.css';

import { languageValue } from "./DefaultValues";
import translationEN from '../texts/english.json';
import translationNL from '../texts/dutch.json';

function Contactpage (){
    const [isActive, setIsActive] = useState(false);
    const [formSent, setFormSent] = useState(false);

      // eslint-disable-next-line
      const { language, setLanguage } =  useContext(languageValue)
      let text;
      if (language === 'nl'){
        text = translationNL
      }
      if (language === 'en'){
        text = translationEN
      }

    useEffect(() => {
        setIsActive(true);
    }, []);

    useEffect(() => {
        if (formSent) {
            // Delay to allow for CSS transition
            setTimeout(() => {
                setIsActive(true);
            }, 100); // Adjust this delay
        }
    }, [formSent]);

    // Function to create markup for text that includes HTML
    const createMarkup = (htmlText) => {
        return { __html: htmlText };
    };

    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        setFormSent(true)
        e.preventDefault();
        try {
            await makePostRequest(formData);

            setFormData({
                name: '',
                phoneNumber: '',
                email: '',
                message: ''
            }) 
        } catch (error) {
            console.error('Error during form submission:', error)}
    }

    async function makePostRequest(input) {
        try {
            // eslint-disable-next-line
            await fetch('https://codestart-api.nl/codestart/contact', {
            // await fetch('http://localhost:3001/codestart/contact', {
                    method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: input.name,
                    phoneNumber: input.phoneNumber,
                    email: input.email,
                    message: input.message
                })})}
            catch (error){
                console.log(error)
            }}
    



   return (
<motion.div className="singleproductpage-l1" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0, transition:{duration:0.15}}}> 
<div className="singleproductpage-upperpart">

</div>
<div className={`singleproductpage-container ${isActive ? 'active' : ''}`} style={{backgroundColor: "rgba(217, 217, 217, 0.3)"}}>
<div className="singleproductpage-inner-container">
<div className="singleproductpage-textbox-container">
<div className="singleproductpage-header">
{text.contactpage.header}
</div>

<div className="singleproductpage-text" dangerouslySetInnerHTML={createMarkup(text.contactpage.text)}>
</div>

{!formSent && ( // Render the form only if formSent is false
<form onSubmit={handleSubmit} className={`contact-page-form ${isActive ? 'active' : ''}`}>
            <div>
                <label htmlFor="name" className="contact-page-label">{text.contactpage.name}</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="contact-page-input"
                    maxLength="200"
                />
            </div>
            <div>
                <label htmlFor="phoneNumber" className="contact-page-label">{text.contactpage.phone}</label>
                <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="contact-page-input"
                    maxLength="200"
                />
            </div>
            <div>
                <label htmlFor="email" className="contact-page-label">{text.contactpage.email}</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="contact-page-input"
                    maxLength="200"
                />
            </div>
            <div>
                <label htmlFor="message" className="contact-page-label">{text.contactpage.question}</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="contact-page-input"
                    style={{height: "150px", width: "500px"}}
                    maxLength="2000"

                />
            </div>
            <div>
                <button type="submit" className="singleproductpage-button">{text.contactpage.send}</button>
            </div>
        </form>
)}

<img src='Images/checkbox.png' alt="#" className={`contact-page-formsent-image ${formSent ? 'active' : ''}`}></img>
<div className={`contact-page-formsent ${formSent ? 'active' : ''}`}>
        {/* Content to show when form is sent */}
        <br></br>
        Dankjewel! 
        <br></br>
        We nemen snel contact met je op!
    </div>

</div>
</div>
</div>
</motion.div>
    )
}

export default Contactpage;