import React from "react"


function NotFound (){
    return (
<div style={{paddingTop: '50vh', color: 'Red', zIndex:2, position:'relative'}}>
    Page Not Found...  
</div>
    )
}

export default NotFound;