import React, { useEffect, useState, useContext, useRef } from "react"
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

import { languageValue } from "./DefaultValues";
import translationEN from '../texts/english.json';
import translationNL from '../texts/dutch.json';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CSS/productsCSS.css';

function Productspage (){
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    const project1Ref = useRef(null);
    const project2Ref = useRef(null);
    const project3Ref = useRef(null);
    const project4Ref = useRef(null);
    const project5Ref = useRef(null);
    const project6Ref = useRef(null);

          // eslint-disable-next-line
          const { language, setLanguage } =  useContext(languageValue)
          let text;
          if (language === 'nl'){
            text = translationNL
          }
          if (language === 'en'){
            text = translationEN
          }

    useEffect(() => {
        setIsActive(true);
    }, []);

    function NavigateToContact() {
        navigate('/Contact');
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 150);    }

    // Function to create markup for text that includes HTML
    const createMarkup = (htmlText) => {
        return { __html: htmlText };
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // time in milliseconds between each auto-scroll
        cssEase: "linear", // this ensures a smooth continuous scroll
        arrows: false,
        className: "products-project",
    responsive: [
        {
            breakpoint: 1000, // Width less than 1000px
            settings: {
                slidesToShow: 2, // Show 2 slides
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 700, // Width less than 700px
            settings: {
                slidesToShow: 1, // Show 1 slide
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ]
};



const scrollToDiv = (ref, duration = 400) => {
    const element = ref.current;
    if (element) {
        const top = element.getBoundingClientRect().top + window.pageYOffset - 100;
        const startingY = window.pageYOffset;
        const diff = top - startingY;
        let start;

        // Function to perform the scroll animation
        const step = (timestamp) => {
            if (!start) start = timestamp;
            const time = timestamp - start;
            const percentage = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percentage);

            if (time < duration) {
                requestAnimationFrame(step);
            }
        };

        // Start the animation
        requestAnimationFrame(step);
    }
};

   return (
<motion.div className="singleproductpage-l1" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0, transition:{duration:0.15}}}> 
<div className="singleproductpage-upperpart">
</div>
<div className={`singleproductpage-container ${isActive ? 'active' : ''}`} style={{backgroundColor: "rgba(217, 217, 217, 0.3)"}}>
<div className="singleproductpage-inner-container">
<div className="singleproductpage-textbox-container">
<div className="singleproductpage-header">
{text.productspage.header}
</div>
<div className="singleproductpage-subheader">
{text.productspage.subheader}
</div>
<div className="singleproductpage-text" dangerouslySetInnerHTML={createMarkup(text.productspage.text)}>
</div>

<Slider {...settings} className="products-projects">
    
    <div className="products-project" onClick={() => scrollToDiv(project1Ref)}>
    <img src={text.productspage.products.project1.image} alt="#" className="products-project-img" id={project1Ref}></img>
    {text.productspage.products.project1.name}
    </div>
    <div className="products-project" onClick={() => scrollToDiv(project2Ref)}>
    <img src={text.productspage.products.project2.image} alt="#" className="products-project-img" id={project2Ref}></img>
    {text.productspage.products.project2.name}
    </div>
    <div className="products-project" onClick={() => scrollToDiv(project3Ref)}>
    <img src={text.productspage.products.project3.image} alt="#" className="products-project-img" id={project3Ref}></img>
    {text.productspage.products.project3.name}
    </div>
    <div className="products-project" onClick={() => scrollToDiv(project4Ref)}>
    <img src={text.productspage.products.project4.image} alt="#" className="products-project-img" id={project4Ref}></img>
    {text.productspage.products.project4.name}
    </div>
    <div className="products-project" onClick={() => scrollToDiv(project5Ref)}>
    <img src={text.productspage.products.project5.image} alt="#" className="products-project-img" id={project5Ref}></img>
    {text.productspage.products.project5.name}
    </div>
    <div className="products-project" onClick={() => scrollToDiv(project6Ref)}>
    <img src={text.productspage.products.project6.image} alt="#" className="products-project-img" id={project6Ref}></img>
    {text.productspage.products.project6.name}
    </div>
    </Slider>


<div onClick={NavigateToContact} className="singleproductpage-button">
{text.productspage.button}
</div>

<div className="products-page-projects-container" ref={project1Ref}>
<div className="products-page-projects-name">
{text.productspage.products.project1.name}
</div>
<a href="https://www.mapsuntold.io" target="_blank" rel="noopener noreferrer">
<img src={text.productspage.products.project1.image} alt="#" className="products-page-projects-image"></img>
</a>
<div dangerouslySetInnerHTML={createMarkup(text.productspage.products.project1.description)} className="products-page-projects-description">
</div>
</div>
<div className="products-page-projects-container" ref={project2Ref}>
<div className="products-page-projects-name">
{text.productspage.products.project2.name}
</div>
<img src={text.productspage.products.project2.image} alt="#" className="products-page-projects-image"></img>
<div dangerouslySetInnerHTML={createMarkup(text.productspage.products.project2.description)} className="products-page-projects-description">
</div>
</div>
<div className="products-page-projects-container" ref={project3Ref}>
<div className="products-page-projects-name">
{text.productspage.products.project3.name}
</div>
<img src={text.productspage.products.project3.image} alt="#" className="products-page-projects-image"></img>
<div dangerouslySetInnerHTML={createMarkup(text.productspage.products.project3.description)} className="products-page-projects-description">
</div>
</div>
<div className="products-page-projects-container" ref={project4Ref}>
<div className="products-page-projects-name">
{text.productspage.products.project4.name}
</div>
<img src={text.productspage.products.project4.image} alt="#" className="products-page-projects-image"></img>
<div dangerouslySetInnerHTML={createMarkup(text.productspage.products.project4.description)} className="products-page-projects-description">
</div>
</div>
<div className="products-page-projects-container" ref={project5Ref}>
<div className="products-page-projects-name">
{text.productspage.products.project5.name}
</div>
<img src={text.productspage.products.project5.image} alt="#" className="products-page-projects-image"></img>
<div dangerouslySetInnerHTML={createMarkup(text.productspage.products.project5.description)} className="products-page-projects-description">
</div>
</div>
<div className="products-page-projects-container" ref={project6Ref}>
<div className="products-page-projects-name">
{text.productspage.products.project6.name}
</div>
<a href="https://www.kunstenaarskompas.nl" target="_blank" rel="noopener noreferrer">
<img src={text.productspage.products.project6.image} alt="#" className="products-page-projects-image" href="https://www.kunstenaarskompas.nl"></img>
</a>
<div dangerouslySetInnerHTML={createMarkup(text.productspage.products.project6.description)} className="products-page-projects-description">
</div>
</div>

<div onClick={NavigateToContact} className="singleproductpage-button">
{text.productspage.button}
</div>

</div>
</div>
</div>

</motion.div>
    )
}

export default Productspage;