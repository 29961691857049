import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Homepage from "./Homepage.jsx";
import Productspage from "./Products.jsx";
import Aboutuspage from "./AboutUs.jsx";
import Contactpage from "./Contact.jsx";
import NotFound from "./NotFound.jsx";
import Websitespage from "./Websitespage.jsx";
import Webappspage from "./Webappspage.jsx";
import Mobileappspage from "./Mobileappspage.jsx";
import Digitalsolutionspage from "./Digitalsolutionspage.jsx";
import Privacypolicypage from "./Privacypolicypage.jsx";

import { AnimatePresence } from 'framer-motion';


function AnimatedRoutes (){
    const location = useLocation();

   return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Homepage />} />
            <Route path="/Projects" element={<Productspage />} />
            <Route path="/AboutUs" element={<Aboutuspage />} />
            <Route path="/Contact" element={<Contactpage />} />
            <Route path='*' element={<NotFound />} />

            <Route path="/Websitespage" element={<Websitespage />} />
            <Route path="/Webappspage" element={<Webappspage />} />
            <Route path="/Mobileappspage" element={<Mobileappspage />} />
            <Route path="/Digitalsolutionspage" element={<Digitalsolutionspage />} />
            
            <Route path='/privacy' element={<Privacypolicypage />} />


          </Routes>
    </AnimatePresence>
    )
}

export default AnimatedRoutes;