import React, { useEffect, useState, useContext } from "react"
import { motion } from 'framer-motion';

import './CSS/aboutusCSS.css';

import { languageValue } from "./DefaultValues";
import translationEN from '../texts/english.json';
import translationNL from '../texts/dutch.json';

function Aboutuspage (){
    const [isActive, setIsActive] = useState(false);

      // eslint-disable-next-line
      const { language, setLanguage } =  useContext(languageValue)
      let text;
      if (language === 'nl'){
        text = translationNL
      }
      if (language === 'en'){
        text = translationEN
      }

    useEffect(() => {
        setIsActive(true);
    }, []);

    // Function to create markup for text that includes HTML
    const createMarkup = (htmlText) => {
        return { __html: htmlText };
    };

   return (
<motion.div className="singleproductpage-l1" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0, transition:{duration:0.15}}}> 
<div className="singleproductpage-upperpart">

</div>
<div className={`singleproductpage-container ${isActive ? 'active' : ''}`} style={{backgroundColor: "rgba(217, 217, 217, 0.3)"}}>
<div className="singleproductpage-inner-container">
<div className="singleproductpage-textbox-container">
<div className="singleproductpage-header">
{text.aboutuspage.header}
</div>
<div className="singleproductpage-subheader">
{text.aboutuspage.subheader}
</div>
<div className="singleproductpage-text" dangerouslySetInnerHTML={createMarkup(text.aboutuspage.text)}>
</div>
<a href="mailto:jesseknoop98@gmail.com" className="singleproductpage-button">
{text.aboutuspage.button}
</a>
</div>
</div>
</div>
</motion.div>
    )
}

export default Aboutuspage;