import React, { useEffect, useState, useContext } from "react"
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';

import './CSS/singleproductpageCSS.css';

import { languageValue } from "./DefaultValues";
import translationEN from '../texts/english.json';
import translationNL from '../texts/dutch.json';

function Digitalsolutionspage (){
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

          // eslint-disable-next-line
          const { language, setLanguage } =  useContext(languageValue)
          let text;
          if (language === 'nl'){
            text = translationNL
          }
          if (language === 'en'){
            text = translationEN
          }

    useEffect(() => {
        setIsActive(true);
    }, []);

    // Function to create markup for text that includes HTML
    const createMarkup = (htmlText) => {
        return { __html: htmlText };
    };

    function NavigateToContact() {
        navigate('/Contact');
setTimeout(() => {
        window.scrollTo(0, 0);
    }, 150);    }

    function NavigateToWebsitespage() {
        navigate('/Websitespage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToWebappspage() {
        navigate('/Webappspage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToMobileappspage() {
        navigate('/Mobileappspage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToDigitalsolutionspage() {
        navigate('/Digitalsolutionspage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToHomepage() {
        navigate('/');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }

   return (
<motion.div className="singleproductpage-l1" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0, transition:{duration:0.15}}}> 
<div className="singleproductpage-upperpart">

</div>
<div className={`singleproductpage-container ${isActive ? 'active' : ''}`} style={{backgroundImage: `url('/Images/Digitalsolutions-img Small.jpeg')`}}>
<div className="singleproductpage-inner-container">
<div className="singleproductpage-textbox-container">
<div className="singleproductpage-header">
{text.digitalsolutionspage.header}
</div>
<div className="singleproductpage-subheader">
{text.digitalsolutionspage.subheader}
</div>
<div className="singleproductpage-text" dangerouslySetInnerHTML={createMarkup(text.digitalsolutionspage.text)}>
</div>
<div onClick={NavigateToContact} className="singleproductpage-button">
{text.digitalsolutionspage.button}
</div>
</div>
</div>
</div>
<div className="singleproductpage-undercontainer">
<div className="singleproductpage-undercontainer-1" onClick={NavigateToHomepage}>
        <div className="singleproductpage-undercontainer-2" style={{backgroundColor: 'rgba(217, 217, 217, 0.55)'}}>
        </div>
        <div className="singleproductpage-undercontainer-3">    
        Home
        </div>
        </div>
    {location.pathname !== '/Websitespage' && (
 <div className="singleproductpage-undercontainer-1" onClick={NavigateToWebsitespage}>
 <div className="singleproductpage-undercontainer-2" style={{backgroundImage: `url('/Images/Website-img Small.jpeg')`}}>
 </div>
 <div className="singleproductpage-undercontainer-3">    
 {text.homepage.productsection.websites.header}
 </div>
 </div>
    )}
    {location.pathname !== '/Webappspage' && (
         <div className="singleproductpage-undercontainer-1" onClick={NavigateToWebappspage}>
         <div className="singleproductpage-undercontainer-2" style={{backgroundImage: `url('/Images/Webapp-img Small.jpeg')`}}>
         </div>
         <div className="singleproductpage-undercontainer-3">    
         {text.homepage.productsection.webapps.header}
         </div>
         </div>
    )}
    {location.pathname !== '/Mobileappspage' && (
 <div className="singleproductpage-undercontainer-1" onClick={NavigateToMobileappspage}>
 <div className="singleproductpage-undercontainer-2" style={{backgroundImage: `url('/Images/Mobileapp-img Small.jpeg')`}}>
 </div>
 <div className="singleproductpage-undercontainer-3">    
 {text.homepage.productsection.mobileapps.header}
 </div>
 </div>
    )}
    {location.pathname !== '/Digitalsolutionspage' && (
 <div className="singleproductpage-undercontainer-1" onClick={NavigateToDigitalsolutionspage}>
 <div className="singleproductpage-undercontainer-2" style={{backgroundImage: `url('/Images/Digitalsolutions-img Small.jpeg')`}}>
 </div>
 <div className="singleproductpage-undercontainer-3">    
 {text.homepage.productsection.digitalsolutions.header}
 </div>
 </div>
    )}
</div>
</motion.div>
    )
}

export default Digitalsolutionspage;