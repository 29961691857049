import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./NavBar.jsx";
import Backgroundvideo from "./Backgroundvideo.jsx";
import './CSS/defaultCSS.css';
import { navBarValue, languageValue } from "./DefaultValues.jsx";
import AnimatedRoutes from "./AnimatedRoutes.jsx";
import { useTranslation } from 'react-i18next';

function Router() {
  const [navBarVal, setNavBarVal] = useState(false);
  const [language, setLanguage] = useState('nl'); // Initialize with a default language
  const [settings, setSettings] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!settings) {
      setLanguage(t('language'));
      setSettings(true);
    }
  }, [settings, t]); // Dependency array

  return (
    <div>
      <languageValue.Provider value={{ language, setLanguage }}>
        <navBarValue.Provider value={{ navBarVal, setNavBarVal }}>
          <BrowserRouter>
            <NavBar />
            <Backgroundvideo />
            <AnimatedRoutes />
          </BrowserRouter>
        </navBarValue.Provider>
      </languageValue.Provider>
    </div>
  );
}

export default Router;
