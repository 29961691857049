import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

import './CSS/homepageCSS.css';
import { motion } from 'framer-motion';

import { languageValue } from "./DefaultValues";
import translationEN from '../texts/english.json';
import translationNL from '../texts/dutch.json';

function Homepage (){
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

      // eslint-disable-next-line
      const { language, setLanguage } =  useContext(languageValue)
      let text;
      if (language === 'nl'){
        text = translationNL
      }
      if (language === 'en'){
        text = translationEN
      }

    useEffect(() => {
        setIsActive(true);
    }, []);

    const useVisibilityFade = () => {
        const [isVisible, setIsVisible] = useState(false);
        const ref = useRef(null);
    
        useEffect(() => {
            const currentRef = ref.current;
            const observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    setIsVisible(entry.isIntersecting);
                },
                { threshold: 0.1 }
            );
    
            if (currentRef) {
                observer.observe(currentRef);
            }
    
            return () => observer.disconnect();
        }, []);
    
        return [isVisible, ref];
    };
    const [isVisible1, ref1] = useVisibilityFade();
    const [isVisible2, ref2] = useVisibilityFade();
    // eslint-disable-next-line
    const [isVisible3, ref3] = useVisibilityFade();
        // eslint-disable-next-line
        const [isVisible4, ref4] = useVisibilityFade();



    function NavigateToWebsitespage() {
        navigate('/Websitespage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToWebappspage() {
        navigate('/Webappspage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToMobileappspage() {
        navigate('/Mobileappspage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }
    function NavigateToDigitalsolutionspage() {
        navigate('/Digitalsolutionspage');
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 150);    }

    function NavigateToContact() {
              navigate('/Contact');
      setTimeout(() => {
              window.scrollTo(0, 0);
          }, 150);    }

   return (
<motion.div className="homepage-l1" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0, transition:{duration:0.15}}}> 
<div className="homepage-header-container-1">
<div className={`homepage-header-container ${isActive ? 'active' : ''}`}>
    {text.homepage.banner.header}
  </div>
  <div className={`homepage-subheader-container ${isActive ? 'active' : ''}`}>
    {text.homepage.banner.subheader}
    </div>
    <Link to="LearnMoreButtonID" smooth={true} duration={500}>
    <div className={`learn-more-button ${isActive ? 'active' : ''}`}>
        {text.homepage.banner.button}
        </div>
        </Link>
        
        <Link to="LearnMoreButtonIDSmall" smooth={true} duration={500}>
        <div className={`learn-more-button-small ${isActive ? 'active' : ''}`}>
        {text.homepage.banner.button}
        </div>
        </Link>


</div>  
<div className="homepage-l2" id="LearnMoreButtonID" ref={ref1} style={{opacity: isVisible1 ? 1 : 0, transition: 'opacity 0.5s', transitionDelay: isVisible1 ? '0.05s' : '0s'}}>
    <div className="homepage-l3">

    <div className="websites-container">
            <div className="websites-main-container">
            <div className="websites-header-subheader-button-container">
            <div className="header-info">
                {text.homepage.productsection.websites.header}
                </div>
                <div className="subheader-info">
                {text.homepage.productsection.websites.text}

                </div>
                <div onClick={NavigateToWebsitespage} className="button-info">
                {text.homepage.productsection.websites.button}
                </div>            
                </div>
            </div>
            </div>
        <div className="web-apps-container">
            <div className="webapps-main-container">
            <div className="webapps-header-subheader-button-container">
            <div className="header-info">
                {text.homepage.productsection.webapps.header}
                </div>
                <div className="subheader-info">
                {text.homepage.productsection.webapps.text}
                </div>
                <div onClick={NavigateToWebappspage} className="button-info">
                {text.homepage.productsection.webapps.button}
                </div>
                </div>
   </div>
        </div>
        <div className="mobile-apps-container">
            <div className="mobileapps-main-container">
            <div className="mobileapps-header-subheader-button-container">

            <div className="header-info">
            {text.homepage.productsection.mobileapps.header}
            </div>
            <div className="subheader-info">
            {text.homepage.productsection.mobileapps.text}
            </div>
            <div onClick={NavigateToMobileappspage} className="button-info">
            {text.homepage.productsection.mobileapps.button}
            </div>
            </div>
            </div>
   </div>
        <div className="digital-solutions-container">
            <div className="digitalsolutions-main-container">
            <div className="digitalsolutions-header-subheader-button-container">

            <div className="header-info">
            {text.homepage.productsection.digitalsolutions.header}
            </div>
            <div className="subheader-info">
            {text.homepage.productsection.digitalsolutions.text}
            </div>
            <div onClick={NavigateToDigitalsolutionspage} className="button-info">
            {text.homepage.productsection.digitalsolutions.button}
            </div>
            </div>
            </div>
        </div>
    </div>
</div>

<div className="homepage-l2-small" id="LearnMoreButtonIDSmall" ref={ref3}>
    <div className="homepage-l3-small">

        <div className="homepage-l3-small-background-websites">
        <div className="homepage-l3-small-overlay">
        <div className="homepage-l3-small-title">
        {text.homepage.productsection.websites.header}
        </div>
        <div onClick={NavigateToWebsitespage} className="homepage-l3-small-button-container">
        <div className="homepage-l3-small-button">
        {text.homepage.productsection.websites.button}
        </div>
        </div>
        </div>
        </div>

        <div className="homepage-l3-small-background-webapps">
        <div className="homepage-l3-small-overlay">
        <div className="homepage-l3-small-title">
        {text.homepage.productsection.webapps.header}
        </div>
        <div onClick={NavigateToWebappspage} className="homepage-l3-small-button-container">
        <div className="homepage-l3-small-button">
        {text.homepage.productsection.webapps.button}
        </div>
        </div>
        </div>
        </div>

        <div className="homepage-l3-small-background-mobileapps">
        <div className="homepage-l3-small-overlay">
        <div className="homepage-l3-small-title">
        {text.homepage.productsection.mobileapps.header}
        </div>
        <div onClick={NavigateToMobileappspage} className="homepage-l3-small-button-container">
        <div className="homepage-l3-small-button">
        {text.homepage.productsection.mobileapps.button}
        </div>
        </div>
        </div>
        </div>

        <div className="homepage-l3-small-background-digital-solutions">
        <div className="homepage-l3-small-overlay">
        <div className="homepage-l3-small-title">
        {text.homepage.productsection.digitalsolutions.header}
        </div>
        <div onClick={NavigateToDigitalsolutionspage} className="homepage-l3-small-button-container">
        <div className="homepage-l3-small-button">
        {text.homepage.productsection.digitalsolutions.button}
        </div>
        </div>
        </div>
        </div>

    </div>
</div>

<div className="homepage-l4" ref={ref2} style={{opacity: isVisible2 ? 1 : 0, transition: 'opacity 0.5s', transitionDelay: isVisible2 ? '0.05s' : '0s'}}>
    <div className="homepage-l5">
        <div className="homepage-l5-header">
        {text.homepage.contactsection.header}
        </div>
        <div className="homepage-l5-subheader">
        {text.homepage.contactsection.text}
        </div>
        <div className="l5-button-button">
        <div onClick={NavigateToContact} className="l5-button-anchor">
        {text.homepage.contactsection.button}
        </div>

        </div>
</div>
</div>

<div className="homepage-l4-small" ref={ref4}>
    <div className="homepage-l5-small">
        <div className="homepage-l5-small-header">
        {text.homepage.contactsection.header}
        </div>
        <div className="homepage-l5-small-subheader">
        {text.homepage.contactsection.text}
        </div>
        <div className="l5-small-button-button">
        <div onClick={NavigateToContact} className="l5-button-anchor">
        {text.homepage.contactsection.button}
        </div>

        </div>
</div>
</div>

<div className="homepage-bottom">

</div>
</motion.div>
    )
}

export default Homepage;