import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './components/Router.jsx';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next'; // Import I18nextProvider
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'; // Import initReactI18next
// Import your translation files
import translationEN from './texts/english.json';
import translationNL from './texts/dutch.json';


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  }
};

i18n
  .use(LanguageDetector) // detects user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // use en if detected lng is not available
    detection: {
      order: ['navigator'], // You can also include 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', etc.
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

// No need to export i18n here
// export default i18n; <-- Remove this line

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}> {/* Wrap your app in I18nextProvider */}
      <Router />
    </I18nextProvider>
  </React.StrictMode>
);