import React, {useEffect, useRef} from "react"

import './CSS/backgroundvideoCSS.css';

function Backgroundvideo (){

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 0.5;
        }
    }, []);

   return (
    <div className="video-container">
    <video ref={videoRef} autoPlay loop muted playsInline className="background-video">
      <source src="/Images/black_-_13495 (720p).mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);
}

export default Backgroundvideo;