import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import './CSS/navbarCSS.css';

import { languageValue } from "./DefaultValues";
import translationEN from '../texts/english.json';
import translationNL from '../texts/dutch.json';

function NavBar (){
    const [isActive, setIsActive] = useState(false);
    const [isActiveDropMenu, setIsActiveDropMenu] = useState(false);

    const { language, setLanguage } =  useContext(languageValue)
    let text;
    if (language === 'nl'){
      text = translationNL
    }
    if (language === 'en'){
      text = translationEN
    }

useEffect(() => {
  setIsActive(true);

  // Toggle body overflow based on dropdown menu state
  if (isActiveDropMenu) {
      document.body.style.overflow = 'hidden';
  } else {
      document.body.style.overflow = 'auto'; // or 'scroll'
  }

  // Cleanup function to reset body overflow when the component unmounts
  return () => {
      document.body.style.overflow = 'auto'; // or 'scroll'
  };
}, [isActiveDropMenu]); // Dependency array includes isActiveDropMenu

    const navigate = useNavigate();


    function NavigateToLogo() {
      setIsActiveDropMenu(false)
        navigate('/');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);    }

    function NavigateToProducts() {
      setIsActiveDropMenu(false)
      navigate('/Projects');
      setTimeout(() => {
        window.scrollTo(0, 0);
    }, 150);  }

    function NavigateToAboutUs() {
      setIsActiveDropMenu(false)
        navigate('/AboutUs');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);    }

    function NavigateToContact() {
      setIsActiveDropMenu(false)
        navigate('/Contact');
setTimeout(() => {
        window.scrollTo(0, 0);
    }, 150);    }

    function ChangeLanguage(e) {
      if( e === 'nl'){
        setLanguage('nl')
      } else if( e === 'en'){
        setLanguage('en')
      }
    }




   return (
    <div className={`navbar-l1 ${isActive ? 'active' : ''}`}>

    <div onClick={NavigateToLogo} className="nav-item-logo nav-logo">
    {text.navbar.name}
    </div>
    <div className="right-group">
    <div onClick={NavigateToProducts} className="nav-items">
    {text.navbar.products}
    </div>
    <div onClick={NavigateToAboutUs} className="nav-items">
    {text.navbar.aboutus}
    </div>
    <div onClick={NavigateToContact} className="nav-items">
    {text.navbar.contact}
    </div>
    <div className="nav-language-container">
    <div
        className={`nav-language ${language === 'en' ? 'active' : ''}`}
        onClick={() => ChangeLanguage('en')}>
        EN
      </div>
      <div
        className={`nav-language ${language === 'nl' ? 'active' : ''}`}
        onClick={() => ChangeLanguage('nl')}>
        NL
      </div>
    </div>
    <div className="nav-kruis-container" onClick={() => setIsActiveDropMenu(prevState => !prevState)}>
      <div className={`upper-streep ${isActiveDropMenu ? 'active' : ''}`}></div>
      <div className={`middle-streep ${isActiveDropMenu ? 'active' : ''}`}></div>
      <div className={`lower-streep ${isActiveDropMenu ? 'active' : ''}`}></div>
    </div>
    </div>
    <div className={`dropdown ${isActiveDropMenu ? 'active' : ''}`}>
      <div className="dropdown-inner">
    <div onClick={NavigateToProducts} className={`nav-items-dropdown ${isActiveDropMenu ? 'active' : ''}`}>
    {text.navbar.products}
    </div>
    <div onClick={NavigateToAboutUs} className={`nav-items-dropdown ${isActiveDropMenu ? 'active' : ''}`}>
    {text.navbar.aboutus}
    </div>
    <div onClick={NavigateToContact} className={`nav-items-dropdown ${isActiveDropMenu ? 'active' : ''}`}>
    {text.navbar.contact}
    </div>
    {/* <div className={`nav-items-dropdown ${isActiveDropMenu ? 'active' : ''}`}> */}
    {/* <div className="nav-language-container-dropdown"> */}

    <div className={`nav-language-container-dropdown ${isActiveDropMenu ? 'active' : ''}`}>
    <div
        className={`nav-language-dropdown ${language === 'en' ? 'active' : ''}`}
        onClick={() => ChangeLanguage('en')}>
        EN
      </div>
      <div
        className={`nav-language-dropdown ${language === 'nl' ? 'active' : ''}`}
        onClick={() => ChangeLanguage('nl')}>
        NL
      </div>
      {/* </div> */}
      </div>
    </div>
    </div>
  </div>
    )
}

export default NavBar;